$color: #FED782;

//media_mixin
@mixin _1400 {
  @media (min-width: 1400px){
    @content;
  }
}

@mixin _1170 {
  @media (min-width: 1170px){
    @content;
  }
}

@mixin _979 {
  @media (min-width: 979px){
    @content;
  }
}

@mixin _768 {
  @media (min-width: 768px){
    @content;
  }
}

@mixin _600 {
  @media (min-width: 600px){
    @content;
  }
}

@mixin _480 {
  @media (min-width: 480px){
    @content;
  }
}

@mixin _350{
  @media (min-width: 350px){
    @content;
  }
}