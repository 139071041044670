@import './src/scss/variables';

.popupShow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: absolute;

  @include _350 {
    display: block;
  }

  @include _768 {
    display: flex;
  }

  .fon {
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    cursor: pointer;
    top: 0;
  }

  .popup {
    width: 100%;
    z-index: 120;
    position: fixed;
    display: flex;
    align-content: center;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 0;
    max-width: 1200px;
    font-size: 32px;
    line-height: 120%;
    justify-content: flex-start;


    @include _350 {
      font-size: 21px;
      line-height: 115%;
      width: calc(100% - 50px);
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      max-width: 500px;
     // padding: 70px 70px 60px 70px;
    }

    @include _768 {
      display: flex;
      margin: auto;
      width: 96%;
      flex-wrap: nowrap;
      font-size: 21px;
      line-height: 115%;
      max-width: 100%;
    }


    @include _1170 {
      width: 80%;
      font-size: 32px;
      max-width: 1200px;
      flex-wrap: nowrap;
    }

    @media (max-width: 900px) and (max-height: 500px) {
      width: 75%;
      flex-wrap: nowrap;
      max-width: 100%;
      font-size: 17px;
      line-height: 18px;
    }

    & .close {
      position: absolute;
      right: 0;
      top: -85px;
      cursor: pointer;
      z-index: 5;

      @include _350 {
        position: fixed;
        right: 20px;
        top: 20px;
        display: none;
      }

      @include _768 {
        position: absolute;
        right: 0;
        top: -85px;
        display: block;
      }

      @media (max-width: 900px) and (max-height: 500px){
        display: none;
      }

    }




    & .img {
      padding-right: 65px;
      width: 100%;
      line-height: 20px;

      & img {
        max-width: 538px;
        width: 100%;
        border-radius: 16px 0 0 16px;

        @include _350 {
          border-radius: 16px 16px 0 0;
        }

        @include _768 {
          border-radius: 16px 0 0 16px;
        }

        @include _1170 {
          padding-right: 65px;
          border-radius: 16px 0 0 16px;
        }

        @media (max-width: 900px) and (max-height: 500px){
          border-radius: 16px 0 0 16px;
          line-height: 5px;
        }

      }

      @include _350 {
        width: 100%;
        text-align: center;
        left: 0;
        padding-right: 0;
      }

      @include _768 {
        line-height: 10px;
      }

      @include _979 {
        position: relative;
        width: 100%;
        text-align: left;
        left: auto;
        top: auto;
      }

      @media (max-width: 900px) and (max-height: 500px){
        line-height: 5px;
        //img {
        //  width: 130px;
        //  margin-top: -5px;
        //}
      }


    }

    & .text {
      text-align: center;
      width: 100%;
      color: #1B352F;

      @include _350 {
        padding: 30px 24px;
      }

      @include _979 {
        padding-top: 0px;
        text-align: left;
      }

      @include _1170 {
        text-align: left;
      }

      & h1 {
        font-size: 55px;
        line-height: 110%;
        color: #1B352F;
        font-family: ProximanovaBlack, serif;
        margin-bottom: 20px;

        @include _350 {
          font-size: 28px;
          line-height: 110%;
          margin-bottom: 15px;
        }

        @include _979 {
          font-size: 55px;
          line-height: 110%;
          margin-bottom: 20px;
          margin-top: 0;
        }


        @media (max-width: 900px) and (max-height: 500px) {
          font-size: 24px;
          line-height: 100%;
        }

      }

      & strong {
        font-family: ProximanovaBold, serif;
      }

      & div {
        max-width: 420px;
      }

    }

  }

  & .closeMobile {

    & img {
      max-width: 44px;
    }

    @include _350 {
      position: fixed;
      right: 20px;
      top: 20px;
      z-index: 333;
      display: block;
    }

    @include _768 {
      display: none;
    }

    @media (max-width: 900px) and (max-height: 500px){
      display: block;
    }

  }

}
