@import './src/scss/variables';
//
//.swiper {
//  width: 100%;
//  height: 100%;
//  max-width: 1200px;
//}
//
//.swiper-slide {
//  text-align: center;
//  font-size: 18px;
//  //height: 450px;
//
//  /* Center slide text vertically */
//  display: -webkit-box;
//  display: -ms-flexbox;
//  display: -webkit-flex;
//  display: flex;
//  -webkit-box-pack: center;
//  -ms-flex-pack: center;
//  -webkit-justify-content: center;
//  justify-content: center;
//  -webkit-box-align: center;
//  -ms-flex-align: center;
//  -webkit-align-items: center;
//  align-items: center;
//  flex-wrap: wrap;
//
//  @include _600 {
//    justify-content: flex-start;
//  }
//
//  @include _1170 {
//    justify-content: center;
//  }
//
//}
//



.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  flex-wrap: wrap;

    @include _600 {
      justify-content: flex-start;
    }

    @include _1170 {
      justify-content: center;
    }

}


.swiper-slide img {
  display: block;
  width: 100%;
  object-fit: cover;

  @include _350 {
    width: 100%;
  }

  @include _768 {
    width: 100%;
  }

}

.swiper-slide .img {
  text-align: left;
  width: 100%;

  @include _350 {
    max-height: 290px;
  }

  @include _768 {
    max-height: 385px;
  }

}

.swiper-slide .text {
  padding: 30px 23px;
  text-align: left;
  width: 100%;
  font-family: 'Proximanova', serif;
  font-size: 16px;
  line-height: 120%;
  background: #fff;
  border-radius: 0 0 10px 10px;
  height: 90px;

  @include _350 {
    height: 90px;
  }

  @include _480 {
    height: 110px;
  }

  @include _600 {
    height: 110px;
  }
  @include _768 {
    height: 120px;
  }

  @include _1170 {
    height: 110px;
    width: 100%;
  }

  & h2 {
    font-family: 'ProximanovaBold', serif;
    font-size: 20px;
    line-height: 120%;
    color: #000000;
    padding: 0;
    margin: 0;
  }

}

.swiper-slide div h1 {
  text-align: center;
}

.swiper-button-prev-unique {
  background-image: url("../../assets/img/button_left.png");
  height: 114px !important;
  width: 114px !important;
  margin: 340px 0 0 -120px;
  left: 0;
  position: absolute;
  cursor: pointer;

  &:hover {
    background-image: url("../../assets/img/button_left_hover.png");
  }

  @include _350 {
    display: none;
  }

  @include _1400 {
    display: block;
  }

}

.swiper-button-next-unique {
  background-image: url("../../assets/img/button_right.png");
  height: 114px !important;
  width: 114px !important;
  margin: 340px -120px 0px 0px;
  position: absolute;
  cursor: pointer;
  right: 0;

  &:hover {
    background-image: url("../../assets/img/button_right_hover.png");
  }

  @include _350 {
    display: none;
  }

  @include _1400 {
    display: block;
  }

}

.swiper-button-next-unique-mobile {
  background-image: url("../../assets/img/button_right_mobile.png");
  position: absolute;
  right: -25px;
  z-index: 3;
  width: 67px !important;
  height: 134px !important;
  cursor: pointer;
  margin: 0px 0 335px 0;
  outline: none;
  background-color:transparent;
  -webkit-tap-highlight-color: transparent;
  top: 210px;

  &:focus{
    outline: none;
  }
  @include _768 {
    margin: 0 0 120px 0;
  }
  @include _1170 {
    margin: 340px 0 0 0;
  }
  @include _1400 {
    display: none;
  }

}

.swiper-button-prev-unique-mobile {
  background-image: url("../../assets/img/button_left_mobile.png");
  position: absolute;
  left: -25px;
  z-index: 3;
  width: 67px !important;
  height: 134px !important;
  cursor: pointer;
  margin: 0px 0 335px 0;
  outline: none;
  background-color:transparent;
  -webkit-tap-highlight-color: transparent;
  top: 210px;


  &:focus{
    outline: none;
  }

  @include _768 {
    margin: 0 0 120px 0;
  }
  @include _1170 {
    margin: 340px 0 0 0;
  }
  @include _1400 {
    display: none;
  }

}

.swiper-pagination-bullet-active {
  background: #6c0668 !important;
}

