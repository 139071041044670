@import "fonst.scss";
@import "variables.scss";

* {
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: 'Proximanova', serif;
}

#root {
  overflow: hidden;
}

.AppIndex {
  background-image: url("../../public/img/fon.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  font-family: 'Proximanova', serif;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #ffffff;
  font-size: 32px;
  line-height: 120%;

  @include _350 {
    background-image: url("../../public/img/fon-mobile.jpg");
    font-size: 21px;
    line-height: 115%;
    //background-position: 46%;
    background-position: 53% 0;
    background-size: auto;
    height: auto;
  }

  @include _768 {
    height: 100vh;
    background-size: cover;
  }

  @include _1170 {
    background-image: url("../../public/img/fon.jpg");
    font-size: 32px;
    line-height: 120%;
    height: 100vh;
    background-size: cover;
  }

}

h1 {
  font-style: normal;
  font-size: 80px;
  line-height: 100%;
  font-family: 'ProximanovaBlack', serif;
  padding: 0;
  margin: 0;

  @include _350 {
    font-size: 40px;
  }

  @include _1170 {
    font-size: 80px;
  }

}

.container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @include _350 {
    //max-width: calc(100% - 50px);
    //padding: 25px 25px  0 25px;
    align-items: end;
    height: auto;
  }

  @include _768 {
    height: 100%;
    align-items: center;
  }

  @include _1170 {
    width: calc(100% - 50px);
    max-width: 1200px;
    padding: 0;
    align-items: center;
  }

  @include _1400 {
    position: relative;
  }

}

.swiper {
  width: 1200px;
}

@media screen and (min-width: 540px) {
  .swiper {
    width: 540px;
  }
}

@media screen and (min-width: 768px) {
  .swiper {
    width: 768px;
  }
}
@media screen and (min-width: 1024px) {
  .swiper {
    width: 1024px;
  }
}

.containerSlider {
  position: relative;
}


//.containerSlider {
//  max-width: 100% !important;
//  width: 47%;
//  //@include _350 {
//  //  display: flex;
//  //  flex-direction: column;
//  //}
//
//}

.click {
  cursor: pointer;

  &:hover {
    color: #fff;
  }

}


.textIndex {
  width: 55%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;

  @include _350 {
    width: 100%;
    padding: 0 25px;
    margin-top: 300px;
  }

  @include _768 {
    width: 55%;
    margin-bottom: 0;
    margin-top: 0;
  }

  @include _1170 {
    width: 55%;
    margin-bottom: 0;
    margin-top: 0;
  }

  & h1 {
    margin-bottom: 10px;
    margin-top: 10px;

    @include _480 {
      width: 100%;
    }

    @media (max-width: 850px) and (max-height: 400px){
      font-size: 20px;
      margin-top: 35px;
    }

  }

  & p {
    width: 87%;
    padding: 0;
    margin: 0 0 25px 0;

    @include _350 {
      margin: 0 0 10px 0;
      color: rgba(255, 255, 255, 0.75);
      width: 99%;
    }

    @include _480 {
      max-width: 380px;
    }

    @include _768 {
      margin: 0 0 25px 0;
      color: #fff;
      width: 87%;
    }

    @include _1170 {
      max-width: 100%;
    }

  }

  & span {
    width: 100%;
    display: block;
    color: $color;
    font-style: italic;
    font-family: ProximanovaBold, serif;
    margin-bottom: 70px;

    @include _350 {
      font-size: 24px;
      margin-bottom: 25px;
    }

    @include _1170 {
      font-size: 36px;
      margin-bottom: 70px;
    }

    @media (min-width: 1300px) and (max-height: 800px){
      margin-bottom: 0;
    }

  }
}

.bottomIndex {
  background: #f6f0f0;
  overflow: hidden;
  padding: 0 25px;

  & .marqueeBlock {
    padding: 115px 0;
    font-weight: 600;
    font-size: 45px;
    line-height: 120%;
    letter-spacing: 0.04em;
    color: #006155;
    font-family: Proximanova, serif;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% + 50px);
    margin-left: -25px;

    @include _350 {
      font-size: 31px;
      width: 112%;
      padding: 50px 0;
    }

    @include _1170 {
      font-size: 45px;
      width: calc(100% + 50px);
      margin-left: -25px;
      padding: 115px 0;
    }

    & .circle {
      width: 13px;
      height: 13px;
      background: #000;
      border-radius: 100px;
      margin: 0 10px;
    }

  }

}


.mobileDiv {
  display: none;

  @include _350 {
    background: #006A5E;
    width: 100%;
    position: relative;
    bottom: 0px;
    padding: 35px 25px;
    left: 0;
    display: flex;
    justify-content: center;

    & .loading {
      display: block;
      max-width: 270px;
    }
  }

  @include _768{
    display: none;
  }

}

.bottomArrow {
  background-image: url("../../public/img/scroll_down.png");
  background-repeat: no-repeat;
  height: 50px;
  width: 50px;
  bottom: 31px;
  position: absolute;
  left: 50%;
  margin-left: -25px;

  @include _350{
    display: none;
  }

  @include _768 {
    display: block;
  }

}




.loading {
  background: $color;
  color: #2F3032;
  padding: 20px 27px;
  cursor: pointer;
  font-size: 16px;
  font-family: ProximanovaBold, serif;
  border-radius: 100px;

  @include _350 {
    display: none;
    padding: 20px 21px;
    font-size: 15px;
  }

  @include _1170 {
    display: block;
    padding: 20px 27px;
  }

  &:hover {
    background: #FFE6AF;
  }

  &:active {
    background: #fff;
  }

}

.h15 {
  height: 15px;
}

.loadingPl {
  @include _768 {
    display: block;
    padding: 11px 27px;
  }

  @media (min-width: 1300px) and (max-height: 800px){
    position: absolute;
    bottom: -125px;
  }

}


.loadingMobile {
  background: $color;
  color: #2F3032;
  padding: 20px 27px;
  cursor: pointer;
  font-size: 16px;
  font-family: ProximanovaBold, serif;
  border-radius: 100px;
  max-width: 266px;
  width: 100%;
  text-align: center;
  margin: 60px auto 0;

  @include _768 {
    margin: 25px auto 0;
  }

  @include _1170 {
    display: none;
  }

}


.sliderBlockTitle {
  display: flex;
  justify-content: space-between;
  margin-top: 140px;
  margin-bottom: 75px;
  width: 100%;

  @include _350 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  @include _1170 {
    margin-top: 140px;
    margin-bottom: 75px;
  }

  & .titleSlider {
    font-size: 50px;
    line-height: 61px;
    font-family: ProximanovaBlack, serif;
    color: #006155;
    text-transform: uppercase;

    @include _350 {
      font-size: 32px;
      line-height: 100%;
    }

    @include _1170 {
      font-size: 50px;
      line-height: 61px;
    }

  }
}

.sliderBlock {
  max-width: 1200px;
  width: 100%;
  height: 450px;
  position: relative;
  right: 0;

  @media (min-width: 320px){
    max-width: 320px;
    margin: 0 auto;
  }

  @include _768 {
    height: 555px;
  }

  @media (min-width: 800px){
    max-width: 800px;
    margin-bottom: 40px;
  }

  @media (min-width: 1200px){
    max-width: 1200px;
    height: 450px;
    
  }

}

.header {
  text-transform: uppercase;
  padding-top: 25px;
  position: absolute;
  top: 0;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  align-items: center;

  @include _350 {
    flex-wrap: wrap;
    padding: 25px 25px  0 25px;
  }

  @include _768 {
    flex-wrap: nowrap;
  }

}


.logo{
  @include _350 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  @include _768 {
    display: flex;
    flex-wrap: nowrap;
    width: auto;
  }

  & a {
    font-family: "ProximanovaBold", serif;
    font-size: 30px;
    line-height: 38px;
    color: $color;
    text-decoration: none;

    @include _350 {
      width: 100%;
    }

    @include _768 {
      width: auto;
    }

  }

}

.line {
  width: 2px;
  margin: 0 18px;
  height: 35px;
  background: #fff;

  @include _350 {
    display: none;
  }

  @include _768 {
    display: flex;
  }

}

.header_text {
  letter-spacing: 1px;
}


.footer {
  height: 90px;
  width: calc(100% + 50px);
  background: #006B5B;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #ffffff;
  margin-left: -25px;

  @include _350 {
    font-size: 18px;
  }

  & span {
    color: #EAE3D5;
    padding-left: 10px;
  }
}
